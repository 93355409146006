import {AxiosResponse} from 'axios';
import {AxiosInstance} from 'axios';

export interface DicomHeader {
  tag: string;
  header: string;
  description: string;
  modality: string;
  level: string;
  group: string;
  valueType: 'string' | 'int' | 'decimal';
}

export interface DicomHeaderResponse {
  tag: string;
  header: string;
  description: string;
  modality: string;
  level: string;
  group: string;
  value_type: 'string' | 'int' | 'decimal';
}

export const responseToDicomHeader = (
  dicomHeaderResp: DicomHeaderResponse
): DicomHeader => {
  return {
    tag: dicomHeaderResp.tag,
    header: dicomHeaderResp.header,
    description: dicomHeaderResp.description,
    modality: dicomHeaderResp.modality,
    level: dicomHeaderResp.level,
    group: dicomHeaderResp.group,
    valueType: dicomHeaderResp.value_type,
  };
};

export const fetchDICOMHeaders = (http: AxiosInstance) => {
  return http
    .get('/v1/dicoms/headers')
    .then((response: AxiosResponse<{[key: string]: DicomHeaderResponse}>) => {
      const {data} = response;
      return Object.entries(data).map(([, dicomHeader]) =>
        responseToDicomHeader(dicomHeader)
      );
    });
};
