import React, {useState, useEffect, useMemo} from 'react';
import {useParams, useNavigate} from 'react-router-dom';
import {
  HiOutlineShieldExclamation,
  HiOutlineShieldCheck,
  HiOutlineExclamationCircle,
  HiOutlineRefresh,
} from 'react-icons/hi';
import {useQuery, useQueryClient} from 'react-query';
import cx from 'classnames';
import {Helmet} from 'react-helmet';
import {isFinite, toNumber, isNil, includes} from 'lodash';
import {toast} from 'react-toastify';
import ReactTooltip from 'react-tooltip';
import _ from 'lodash';

import {
  useDatasetReview,
  DatasetReviewEventType,
} from '../../../hooks/dataset-review-provider';
import {
  updateDatasetReviewStatusAdmin,
  fetchDatasetDICOMHeaderScanAdmin,
  DatasetReviewState,
  fetchAllDatasetsNeedReviewAdmin,
} from '../../../models/dataset-review';
import {ApplicationShell} from '../../../core/layout/application-shell';
import {Modal} from '../../../core/layout/modal';
import {
  updateDICOMPHIRequest,
  updateDICOMPHI,
  updateDICOMPHIReviewedRequest,
  updateDICOMPHIReviewed,
  fetchDICOMs,
} from '../../../models/dicom';
import {fetchDatasetEvalonReviewAdmin} from '../../../models/evalon';
import {
  DicomocrResultsTable,
  EvalonReportErrorResultsTable,
  EvalonDicomErrorResultsTable,
  HeaderScanResults,
} from '.';
import {reportPHI} from '../../../models/report';
import {ToastMessage} from '../../../core/components/toast';
import {fetchDatasetDicomInfo} from '../../../models/dicom-viewer';
import {DatasetStatusEnum} from 'src/enums/datasetstatusenum';
import {useAxios} from 'src/utils/http';

export const AdminDatasetsResultsReviewPage = () => {
  const q = useParams<{datasetID?: string}>();
  const {datasetReviewState, datasetReviewDispatch} = useDatasetReview();
  const datasetID = isFinite(toNumber(q.datasetID)) ? toNumber(q.datasetID) : 0;

  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const api = useAxios();

  type reviewView = 'dicomocr' | 'error' | 'header' | 'all';
  type modalType =
    | 'reportPHI'
    | 'dicomReview'
    | 'datasetClean'
    | 'datasetReprocess';

  const reportPHIRequest = (studyIDs: string[]) => {
    return studyIDs.map(studyID => {
      return reportPHI(api, studyID)
        .then(() => {
          toast(
            <ToastMessage title={'PHI Reported Successfully'} icon="success" />
          );
        })
        .catch(err => {
          const message = err?.response?.data?.message ?? 'Error reporting PHI';
          toast(<ToastMessage title={message} icon="error" />);
        });
    });
  };

  useEffect(() => {
    if (
      isNil(datasetReviewState.id) ||
      datasetReviewState.id !== `review/${datasetID}`
    ) {
      datasetReviewDispatch({
        type: DatasetReviewEventType.RESET,
        payload: {id: `review/${datasetID}`},
      });
    }
  }, [datasetID, datasetReviewDispatch, datasetReviewState.id]);

  const {data: evalonResults, isLoading: evalonResultsIsLoading} = useQuery(
    ['adminEvalonResults', datasetID],
    () => fetchDatasetEvalonReviewAdmin(api, datasetID),
    {
      enabled: !isNil(datasetID),
      keepPreviousData: true,
      staleTime: 5 * 60 * 1000, // 5 minutes
    }
  );

  const {data: headerScanResults, isLoading: headerScanResultsIsLoading} =
    useQuery(
      ['adminHeaderScanResults', datasetID],
      () => fetchDatasetDICOMHeaderScanAdmin(api, datasetID),
      {
        enabled: !isNil(datasetID),
        keepPreviousData: true,
        staleTime: 5 * 60 * 1000, // 5 minutes
      }
    );

  const {data: datasetDicomInfo, isError: datasetDicomInfoIsError} = useQuery(
    ['datasetDicom', datasetID],
    () => {
      return fetchDatasetDicomInfo(api, datasetID, true);
    },
    {
      enabled: !isNil(datasetID),
      keepPreviousData: true,
      staleTime: 5 * 60 * 1000, // 5 minutes
    }
  );

  const {data: datasets} = useQuery(
    ['adminDatasetsReview'],
    () => {
      return fetchAllDatasetsNeedReviewAdmin(api);
    },
    {
      keepPreviousData: true,
      staleTime: 5 * 60 * 1000, // 5 minutes
    }
  );

  const datasetStatus = useMemo(
    () => datasets?.find(dataset => dataset.id === datasetID)?.status,
    [datasets, datasetID]
  );

  const datasetCanBeSubmittedClean = !includes(
    [
      DatasetStatusEnum.DatasetStatusSamplesPHIDetected,
      DatasetStatusEnum.DatasetStatusDatasetPHIDetected,
    ],
    datasetStatus
  );

  const {data: dicoms, isLoading: dicomsIsLoading} = useQuery(
    [datasetID, 'dicomocrReview', datasetReviewState.dicoms.ids],
    () => {
      return fetchDICOMs(api, datasetReviewState.dicoms.ids);
    },
    {
      enabled: !_.isEmpty(datasetReviewState.dicoms.ids),
      refetchOnWindowFocus: true,
      keepPreviousData: true,
      staleTime: 60 * 60 * 1000, // 1 hour
    }
  );

  useEffect(() => {
    ReactTooltip.rebuild();
  }, [datasetCanBeSubmittedClean]);

  const [activeModalType, activeModalTypeChange] = useState<modalType>();
  const closeModal = () => activeModalTypeChange(undefined);
  const openModal = (modalType: modalType) => activeModalTypeChange(modalType);

  const [reviewTableView, reviewTableViewChange] =
    useState<reviewView>('dicomocr');

  const updateDatasetCleanModal = () => {
    return (
      <Modal
        isOpen={activeModalType === 'datasetClean'}
        onRequestClose={closeModal}
        className="max-w-lg"
      >
        <div className="mb-6">
          <div className="flex items-center mb-3">
            <HiOutlineShieldCheck className="mr-1 w-5 h-auto text-emerald-600" />
            <div className="text-lg">Update Dataset Status Clean</div>
          </div>
          <div className="text-sm text-gray-500">
            Update the status of this dataset to clean after reviewing and
            ensuring there is no PHI present in this dataset. This action can
            not be undone.
          </div>
        </div>
        <div className="flex flex-row items-center justify-between">
          <button className="btn btn-white" onClick={closeModal}>
            Cancel
          </button>
          <button
            className="btn btn-primary"
            onClick={() =>
              updateDatasetReviewStatusAdmin(
                api,
                datasetID,
                DatasetReviewState.CLEAN
              )
                .then(dataset => {
                  closeModal();
                  queryClient.invalidateQueries(['adminDatasetsReview']);
                  // Redirect to datasets review page
                  navigate('/admin/datasets/review');
                  toast(
                    <ToastMessage
                      title={`Dataset ${dataset.id} updated to clean status`}
                      icon="success"
                    />
                  );
                })
                .catch(err => {
                  const message =
                    err?.response?.data?.message ??
                    'Error updating dataset status clean';

                  toast(<ToastMessage title={message} icon="error" />);
                  closeModal();
                })
            }
          >
            Submit
          </button>
        </div>
      </Modal>
    );
  };

  const updateDatasetReprocessModal = () => {
    return (
      <Modal
        isOpen={activeModalType === 'datasetReprocess'}
        onRequestClose={closeModal}
        className="max-w-lg"
      >
        <div className="mb-6">
          <div className="flex items-center mb-3">
            <HiOutlineRefresh className="mr-1 w-5 h-auto" />
            <div className="text-lg">Update Dataset Reprocess</div>
          </div>
          <div className="text-sm text-gray-500">
            Update the status of this dataset to reprocess. This will cause the
            dataset to get re-packed and re-scanned, with all the reports and
            DICOMs marked as PHI removed. After re-processing is complete the
            dataset will show up in the review tab to be reviewed again.
          </div>
        </div>
        <div className="flex flex-row items-center justify-between">
          <button className="btn btn-white" onClick={closeModal}>
            Cancel
          </button>
          <button
            className="btn btn-primary"
            onClick={() =>
              updateDatasetReviewStatusAdmin(
                api,
                datasetID,
                DatasetReviewState.REPROCESS
              )
                .then(dataset => {
                  closeModal();
                  queryClient.invalidateQueries(['adminDatasetsReview']);
                  // Redirect to datasets review page
                  navigate('/admin/datasets/review');
                  toast(
                    <ToastMessage
                      title={`Dataset ${dataset.id} updated to reprocess`}
                      icon="success"
                    />
                  );
                })
                .catch(err => {
                  const message =
                    err?.response?.data?.message ??
                    'Error updating dataset to reprocess';

                  toast(<ToastMessage title={message} icon="error" />);
                  closeModal();
                })
            }
          >
            Submit
          </button>
        </div>
      </Modal>
    );
  };

  const updateDICOMReviewedModal = () => {
    return (
      <Modal
        isOpen={activeModalType === 'dicomReview'}
        onRequestClose={closeModal}
        className="max-w-lg"
      >
        <div className="mb-6">
          <div className="flex items-center mb-3">
            <HiOutlineShieldCheck className="mr-1 w-5 h-auto text-emerald-600" />
            <div className="text-lg">Update DICOMs Clean</div>
          </div>
          <div className="text-sm text-gray-500">
            Update {datasetReviewState.dicoms.ids.length} DICOMs to clean and
            reviewed. All DICOMs in the selected series will be considered
            clean, reviewed, and safe to show to customers.
          </div>
        </div>
        <div className="flex flex-row items-center justify-between">
          <button className="btn btn-white" onClick={closeModal}>
            Cancel
          </button>
          <button
            disabled={
              datasetReviewState.dicoms.ids.length === 0 || dicomsIsLoading
            }
            className="btn btn-primary"
            onClick={() => {
              if (datasetReviewState.dicoms.ids.length > 0) {
                const dicomReviewedRequest: updateDICOMPHIReviewedRequest[] =
                  _.compact(
                    datasetReviewState.dicoms.ids.map(sopID => {
                      const dicom = _.find(dicoms, {sopID});
                      if (dicom === undefined) {
                        return;
                      }

                      return {
                        study_id: dicom.studyID,
                        series_id: dicom.seriesID,
                        phi_has: false,
                      };
                    })
                  );
                updateDICOMPHIReviewed(api, dicomReviewedRequest)
                  .then(() =>
                    queryClient.invalidateQueries([datasetID, 'dicomocrReview'])
                  )
                  .finally(() => {
                    datasetReviewDispatch({
                      type: DatasetReviewEventType.RESET,
                      payload: {id: `review/${datasetID}`},
                    });
                    closeModal();
                  });
              }
            }}
          >
            Submit
          </button>
        </div>
      </Modal>
    );
  };

  const reportPHIModal = () => {
    return (
      <Modal
        isOpen={activeModalType === 'reportPHI'}
        onRequestClose={closeModal}
        className="max-w-lg"
      >
        <div className="mb-6">
          <div className="flex items-center mb-3">
            <HiOutlineShieldExclamation className="mr-1 w-5 h-auto text-red-600" />
            <div className="text-lg">Report PHI</div>
          </div>
          <div className="text-sm text-gray-500">
            Report PHI in {datasetReviewState.dicoms.ids.length} DICOMs and{' '}
            {datasetReviewState.reports.ids.length} Reports. All DICOMs in the
            selected series will be marked as PHI. This dataset will also be
            updated to phi detected status. This action can not be undone.
          </div>
        </div>
        <div className="flex flex-row items-center justify-between">
          <button className="btn btn-white" onClick={closeModal}>
            Cancel
          </button>
          <button
            disabled={
              datasetReviewState.dicoms.ids.length +
                datasetReviewState.reports.ids.length ===
                0 || dicomsIsLoading
            }
            className="btn btn-danger"
            onClick={() => {
              // Submit DICOMs as PHI
              if (datasetReviewState.dicoms.ids.length > 0) {
                const dicomRequest: updateDICOMPHIRequest[] = _.compact(
                  datasetReviewState.dicoms.ids.map(sopID => {
                    const dicom = _.find(dicoms, {sopID});
                    if (dicom === undefined) {
                      return;
                    }

                    return {
                      study_id: dicom.studyID,
                      series_id: dicom.seriesID,
                      phi_has: false,
                    };
                  })
                );
                updateDICOMPHI(api, dicomRequest)
                  .then(() =>
                    queryClient.invalidateQueries([datasetID, 'dicomocrReview'])
                  )
                  .finally(() => {
                    datasetReviewDispatch({
                      type: DatasetReviewEventType.RESET,
                      payload: {id: `review/${datasetID}`},
                    });
                    closeModal();
                  });
              }

              // Submit reports as PHI
              if (datasetReviewState.reports.ids.length > 0) {
                Promise.all(
                  reportPHIRequest(datasetReviewState.reports.ids)
                ).finally(() => {
                  datasetReviewDispatch({
                    type: DatasetReviewEventType.RESET,
                    payload: {id: `review/${datasetID}`},
                  });
                  closeModal();
                });
              }

              // Update dataset status to phi if not already updated
              if (datasetCanBeSubmittedClean) {
                updateDatasetReviewStatusAdmin(
                  api,
                  datasetID,
                  DatasetReviewState.DIRTY
                )
                  .then(() =>
                    queryClient.invalidateQueries(['adminDatasetsReview'])
                  )
                  .catch(err => {
                    const message =
                      err?.response?.data?.message ??
                      'Error updating dataset status phi detected';

                    toast(<ToastMessage title={message} icon="error" />);
                  });
              }
            }}
          >
            Submit
          </button>
        </div>
      </Modal>
    );
  };

  // Split Results into each part
  const {Dicomocr: dicomocrData = [], Error: evalonErrorData = []} =
    evalonResults || {};

  // Filter report and Dicom error results based on if sopID exists
  const dicomErrorData = evalonErrorData.filter(result => result.sopID);
  const reportErrorData = evalonErrorData.filter(result => !result.sopID);

  return (
    <>
      <Helmet>
        <title>Segmed Openda - Admin - Review Datasets</title>
      </Helmet>

      <ApplicationShell contained={false}>
        <div className="flex flex-row justify-between items-center mb-5">
          <h1 className="text-2xl">Dataset {datasetID} Review</h1>
          <div className="flex">
            <button
              className="btn btn-danger mx-1"
              disabled={
                datasetReviewState.dicoms.ids.length +
                  datasetReviewState.reports.ids.length ===
                0
              }
              onClick={() => openModal('reportPHI')}
            >
              <HiOutlineShieldExclamation className="mr-1 w-5 h-auto" /> Report
              PHI
            </button>
            <button
              className="btn btn-primary mx-1"
              disabled={datasetReviewState.dicoms.ids.length === 0}
              onClick={() => openModal('dicomReview')}
            >
              <HiOutlineShieldCheck className="mr-1 w-5 h-auto" /> Update DICOM
              Clean
            </button>
            <div className="border-l-2 border-gray-200 mx-2"></div>
            <button
              className="btn btn-white mx-1"
              onClick={() => openModal('datasetReprocess')}
            >
              <HiOutlineRefresh className="mr-1 w-5 h-auto" /> Submit Dataset
              Reprocess
            </button>
            <span
              data-tip={
                !datasetCanBeSubmittedClean
                  ? 'Dataset is marked as PHI and must be reprocessed before being submitted as clean'
                  : undefined
              }
              data-event="mouseenter"
              data-event-off="mouseleave click"
            >
              <button
                className="btn btn-primary mx-1"
                onClick={() => openModal('datasetClean')}
                disabled={!datasetCanBeSubmittedClean}
              >
                <HiOutlineShieldCheck className="mr-1 w-5 h-auto" /> Submit
                Dataset Clean
              </button>
            </span>
          </div>
        </div>

        {evalonResultsIsLoading || headerScanResultsIsLoading ? (
          'Loading Dataset Review results...'
        ) : (
          <>
            <div className="flex flex-row mb-3 items-center">
              <div className="text-xl">View: </div>
              <button
                className={cx('btn mx-1', {
                  'btn-primary': reviewTableView === 'dicomocr',
                  'btn-white': reviewTableView !== 'dicomocr',
                })}
                onClick={() => reviewTableViewChange('dicomocr')}
              >
                Dicomocr Results ({dicomocrData.length})
              </button>
              <button
                className={cx('btn mx-1', {
                  'btn-primary': reviewTableView === 'error',
                  'btn-white': reviewTableView !== 'error',
                })}
                onClick={() => reviewTableViewChange('error')}
              >
                Error Results ({evalonErrorData.length})
              </button>
              <button
                className={cx('btn mx-1', {
                  'btn-primary': reviewTableView === 'header',
                  'btn-white': reviewTableView !== 'header',
                })}
                onClick={() => reviewTableViewChange('header')}
              >
                Header Scan Results ({headerScanResults ? 1 : 0})
              </button>
              <button
                className={cx('btn mx-1', {
                  'btn-primary': reviewTableView === 'all',
                  'btn-white': reviewTableView !== 'all',
                })}
                onClick={() => reviewTableViewChange('all')}
              >
                All Results (
                {evalonErrorData.length +
                  dicomocrData.length +
                  (headerScanResults ? 1 : 0)}
                )
              </button>
            </div>
            {evalonResults ? (
              <>
                {['dicomocr', 'all'].includes(reviewTableView) && (
                  <DicomocrResultsTable
                    dicomocrResults={dicomocrData}
                    datasetDicomInfo={datasetDicomInfo}
                    datasetDicomInfoIsError={datasetDicomInfoIsError}
                    datasetID={datasetID}
                    selected={datasetReviewState.dicoms.ids}
                    onSelect={(sopIDs, checked) => {
                      datasetReviewDispatch({
                        type: checked
                          ? DatasetReviewEventType.SELECT
                          : DatasetReviewEventType.UNSELECT,
                        payload: {sopIDs},
                      });
                    }}
                  />
                )}

                {reviewTableView === 'all' && (
                  <hr className="my-1 divide-x divide-gray-400" />
                )}

                {reviewTableView === 'all' && (
                  <hr className="my-1 divide-x divide-gray-400" />
                )}

                {['error', 'all'].includes(reviewTableView) && (
                  <EvalonDicomErrorResultsTable
                    evalonErrorResults={dicomErrorData}
                    datasetDicomInfo={datasetDicomInfo}
                    datasetDicomInfoIsError={datasetDicomInfoIsError}
                    datasetID={datasetID}
                    selected={datasetReviewState.dicoms.ids}
                    onSelect={(sopIDs, checked) => {
                      datasetReviewDispatch({
                        type: checked
                          ? DatasetReviewEventType.SELECT
                          : DatasetReviewEventType.UNSELECT,
                        payload: {sopIDs},
                      });
                    }}
                  />
                )}

                {reviewTableView === 'all' && (
                  <hr className="my-1 divide-x divide-gray-400" />
                )}

                {['error', 'all'].includes(reviewTableView) && (
                  <EvalonReportErrorResultsTable
                    evalonErrorResults={reportErrorData}
                    datasetID={datasetID}
                    selected={datasetReviewState.reports.ids}
                    onSelect={(studyIDs, checked) => {
                      datasetReviewDispatch({
                        type: checked
                          ? DatasetReviewEventType.SELECT
                          : DatasetReviewEventType.UNSELECT,
                        payload: {studyIDs},
                      });
                    }}
                  />
                )}

                {reviewTableView === 'all' && (
                  <hr className="my-1 divide-x divide-gray-400" />
                )}
              </>
            ) : (
              <>
                <div className="m-auto w-min flex flex-nowrap  items-center my-6 p-2 whitespace-nowrap border-gray-300 rounded border text-xl text-center">
                  <HiOutlineExclamationCircle className="text-red-500 mr-2 h-6 w-6" />
                  <div> No Evalon Results Found</div>
                </div>

                {reviewTableView === 'all' && (
                  <hr className="my-1 divide-x divide-gray-400" />
                )}
              </>
            )}
            {headerScanResults ? (
              ['header', 'all'].includes(reviewTableView) && (
                <HeaderScanResults headerScan={headerScanResults} />
              )
            ) : (
              <div className="m-auto w-min flex flex-nowrap  items-center my-6 p-2 whitespace-nowrap border-gray-300 rounded border text-xl text-center">
                <HiOutlineExclamationCircle className="text-red-500 mr-2 h-6 w-6" />
                <div> No Header Scan Results Found</div>
              </div>
            )}
          </>
        )}
      </ApplicationShell>
      {updateDatasetCleanModal()}
      {updateDatasetReprocessModal()}
      {reportPHIModal()}
      {updateDICOMReviewedModal()}
    </>
  );
};
