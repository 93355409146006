import {MinervaSearchConfigHandler} from './minerva-config-handler';
import {getConfig} from '../../../config/config-helper';

const {endpointBase, engineName, endpointBaseAppSearch} = getConfig();

export const MinervaSearchPage = () => {
  return (
    <MinervaSearchConfigHandler
      endpointBase={endpointBase}
      engineName={engineName}
      endpointBaseAppSearch={endpointBaseAppSearch}
      isInternalPage={false}
      useVectorSearchEnabled={true}
      trackSearchType="external_search"
      title="Segmed Openda - Search"
    />
  );
};
