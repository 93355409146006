/* eslint-disable react/no-danger */

import React from 'react';
import {
  ReportSelectionEventType,
  isReportSelected,
  useReportSelection,
} from '../../hooks/report-selection-provider';
import {Link} from 'react-router-dom';
import {DateTime} from 'luxon';
import cx from 'classnames';
import _ from 'lodash';
import {truncateStudyID} from '../../models/report';
import {useAuth} from 'src/hooks/auth';
import {isInternal} from 'src/models/auth';
import {trackEvent} from 'src/utils/tracking';

type TableRowsProps = {
  results: any;
  openedStudyIDChange: (studyID: string) => void;
  groupPatientIDs: boolean;
  dicomsAvailable: string[];
  openedStudyID: string | undefined;
  internalSearchPage?: boolean;
  saveScrollPos: () => void;
};

export const SearchTableRows: React.FC<TableRowsProps> = ({
  results,
  openedStudyIDChange,
  groupPatientIDs,
  dicomsAvailable = [],
  openedStudyID,
  internalSearchPage = false,
  saveScrollPos,
}) => {
  const {authState} = useAuth();
  const internalUser = isInternal(authState.profile!.email);
  const {reportSelectionState, reportSelectionDispatch} = useReportSelection();

  const NestedField = (result: any, isNumber = false): string => {
    if (!_.isObject(result)) {
      return '';
    }

    const res = [];

    if (isNumber) {
      const columns = Object.keys(result);
      for (const column of columns) {
        if (column === '_series_id') {
          res.push(`seriesID: ${result[column as keyof typeof result]}`);
        } else {
          res.push(
            `${column}: ${result[column as keyof typeof result]['min']} - ${
              result[column as keyof typeof result]['max']
            }`
          );
        }
      }
    } else {
      for (const [key, value] of Object.entries(result)) {
        res.push(`"${key}": "${value}"`);
      }
    }

    return `{${res.join(', ')}}`;
  };

  const NestedFields = (results: any, isNumber = false): string => {
    if (results === undefined) {
      return '';
    } else if (!_.isArray(results)) {
      results = [results];
    }

    const res = results.map((result: any) => {
      return NestedField(result, isNumber);
    });
    return `[${res.join(', ')}]`;
  };

  return (
    <>
      {results.map((result: any, i: number) => {
        const samePatientAsNext =
          result.patient_id?.raw === results[i + 1]?.patient_id?.raw;
        const samePatientAsPrevious =
          result.patient_id?.raw === results[i - 1]?.patient_id?.raw;
        const dicomAvailable = dicomsAvailable.includes(
          result['study_id']?.['raw']
        );

        return (
          <tr
            key={result['study_id']?.['raw']}
            className={cx(
              'group hover:bg-gray-100',
              openedStudyID === result['study_id']?.['raw']
                ? 'bg-gray-200'
                : 'bg-white'
            )}
          >
            <td
              className={cx(
                'px-6 py-4 whitespace-nowrap text-sm border-b border-r sticky z-10 group-hover:bg-gray-100 left-0 w-[39px]',
                openedStudyID === result['study_id']?.['raw']
                  ? 'bg-gray-200'
                  : 'bg-white'
              )}
            >
              <input
                type="checkbox"
                className="checkbox-input"
                checked={isReportSelected(
                  reportSelectionState,
                  result['study_id']?.['raw']
                )}
                onChange={e => {
                  const checked = e.target.checked;
                  saveScrollPos();
                  reportSelectionDispatch({
                    type: checked
                      ? ReportSelectionEventType.SELECT
                      : ReportSelectionEventType.UNSELECT,
                    payload: [result['study_id']?.['raw']],
                  });
                }}
              />
            </td>
            <td
              className={cx(
                'px-6 py-4 whitespace-nowrap text-sm sticky z-10 group-hover:bg-gray-100 left-[41px] w-[190px]',
                openedStudyID === result['study_id']?.['raw']
                  ? 'bg-gray-200'
                  : 'bg-white',
                {
                  'border-b': !samePatientAsNext || !groupPatientIDs,
                }
              )}
              dangerouslySetInnerHTML={{
                __html:
                  samePatientAsPrevious && groupPatientIDs
                    ? ''
                    : result['patient_id']?.['raw']?.slice(-10),
              }}
            />
            <td
              className={cx(
                'px-6 py-4 whitespace-nowrap text-sm border-b border-l sticky z-10 group-hover:bg-gray-100 left-[176px] w-[94px]',
                openedStudyID === result['study_id']?.['raw']
                  ? 'bg-gray-200'
                  : 'bg-white'
              )}
            >
              <Link
                to={`/study/${result['study_id']?.['raw']}`}
                className="link"
                preventScrollReset={true}
                onClick={e => {
                  e.preventDefault();
                  saveScrollPos();
                  openedStudyIDChange(result['study_id']?.['raw']);
                }}
              >
                {!!result['isAdditional'] && (
                  <span className="text-yellow-500 text-2xl">•</span>
                )}{' '}
                {truncateStudyID(result['study_id']?.['raw'])}
              </Link>
            </td>
            <td className="px-6 py-4 whitespace-nowrap text-sm border-b">
              {_.trim(result['exam_date']?.['raw']) === ''
                ? ''
                : DateTime.fromISO(result['exam_date']?.['raw']).toLocaleString(
                    DateTime.DATE_MED
                  )}
            </td>
            {internalSearchPage ? (
              <>
                <td
                  className="px-6 py-4 whitespace-nowrap text-sm border-b"
                  dangerouslySetInnerHTML={{
                    __html:
                      result['body_part_llm']?.['snippet'] ||
                      result['body_part_llm']?.['raw'],
                  }}
                />
                <td
                  className="px-6 py-4 whitespace-nowrap text-sm border-b"
                  dangerouslySetInnerHTML={{
                    __html:
                      result['body_part']?.['snippet'] ||
                      result['body_part']?.['raw'],
                  }}
                />
                <td
                  className="px-6 py-4 whitespace-nowrap text-sm border-b"
                  dangerouslySetInnerHTML={{
                    __html:
                      result['modality_llm']?.['snippet'] ||
                      result['modality_llm']?.['raw'],
                  }}
                />
                <td
                  className="px-6 py-4 whitespace-nowrap text-sm border-b"
                  dangerouslySetInnerHTML={{
                    __html:
                      result['modality']?.['snippet'] ||
                      result['modality']?.['raw'],
                  }}
                />
                <td
                  className="px-6 py-4 whitespace-nowrap text-sm border-b"
                  dangerouslySetInnerHTML={{
                    __html:
                      result['contrast']?.['snippet'] ||
                      result['contrast']?.['raw'],
                  }}
                />
              </>
            ) : (
              <>
                <td
                  className="px-6 py-4 whitespace-nowrap text-sm border-b"
                  dangerouslySetInnerHTML={{
                    __html:
                      result['body_part']?.['snippet'] ||
                      result['body_part']?.['raw'],
                  }}
                />
                <td
                  className="px-6 py-4 whitespace-nowrap text-sm border-b"
                  dangerouslySetInnerHTML={{
                    __html:
                      result['modality']?.['snippet'] ||
                      result['modality']?.['raw'],
                  }}
                />
              </>
            )}

            <td className="px-6 py-4 whitespace-nowrap text-sm border-b">
              <Link
                to={`/study/${result['study_id']?.['raw']}/dicomViewer`}
                target="_blank"
              >
                <button
                  onClick={() => {
                    trackEvent('viewdicom_search', {
                      studyId: result.study_id?.raw,
                      user_type: internalUser ? 'internal' : 'external',
                    });
                  }}
                  className="btn btn-xs btn-primary -my-2"
                  disabled={!dicomAvailable}
                >
                  View DICOMs
                </button>
              </Link>
            </td>
            <td
              className="px-6 py-4 whitespace-nowrap text-sm border-b"
              dangerouslySetInnerHTML={{
                __html:
                  result['study_description_llm']?.['raw'] ||
                  result['study_description_llm']?.['snippet'] ||
                  result['report_title']?.['snippet'] ||
                  result['report_title']?.['raw'] ||
                  result['study_id']?.['raw'],
              }}
            />
            <td
              className="px-6 py-4 whitespace-nowrap text-sm border-b"
              dangerouslySetInnerHTML={{
                __html:
                  result['manufacturer']?.['snippet'] ||
                  result['manufacturer']?.['raw'],
              }}
            />
            <td
              className="px-6 py-4 whitespace-nowrap text-sm border-b"
              dangerouslySetInnerHTML={{
                __html: (() => {
                  const ageRaw = result['patient_age']?.['raw'];
                  if (ageRaw) {
                    const ageRegexMatch = ageRaw.match(/^(\d{2,3})Y$/);
                    if (ageRegexMatch) {
                      const ageNumber = parseInt(ageRegexMatch[1], 10);
                      if (ageNumber > 90) {
                        return '90+';
                      }
                    }
                    return ageRaw === 'segmed_PHI_regex'
                      ? '90+'
                      : result['patient_age']?.['snippet'] || ageRaw;
                  } else {
                    return '';
                  }
                })(),
              }}
            />

            <td
              className="px-6 py-4 whitespace-nowrap text-sm border-b"
              dangerouslySetInnerHTML={{
                __html:
                  result['patient_sex']?.['snippet'] ||
                  result['patient_sex']?.['raw'],
              }}
            />
            <td
              className="px-6 py-4 whitespace-nowrap text-sm border-b"
              dangerouslySetInnerHTML={{
                __html:
                  result['model']?.['snippet'] || result['model']?.['raw'],
              }}
            />
            <td
              className="px-6 py-4 whitespace-nowrap text-sm border-b"
              dangerouslySetInnerHTML={{
                __html:
                  result['source_location']?.['snippet'] ||
                  result['source_location']?.['raw'],
              }}
            />
            <td
              className="px-6 py-4 whitespace-nowrap text-sm border-b"
              dangerouslySetInnerHTML={{
                __html: result['site']?.['snippet'] || result['site']?.['raw'],
              }}
            />
            <td
              className="px-6 py-4 whitespace-nowrap text-sm border-b"
              dangerouslySetInnerHTML={{
                __html: result.state?.raw || result.state?.snippet || '',
              }}
            />
            <td
              className="px-6 py-4 whitespace-nowrap text-sm border-b max-w-4xl overflow-x-auto"
              dangerouslySetInnerHTML={{
                __html:
                  result['report']?.['snippet'] || result['report']?.['raw'],
              }}
            />
            <td className="px-6 py-4 whitespace-nowrap text-sm border-b overflow-x-auto max-w-md border">
              {NestedField(result['dicom_headers_study']?.raw)}
            </td>
            <td className="px-6 py-4 whitespace-nowrap text-sm border-b overflow-x-auto max-w-md border">
              {NestedFields(result['dicom_headers_series']?.raw)}
            </td>
            <td className="px-6 py-4 whitespace-nowrap text-sm border-b overflow-x-auto max-w-md border">
              {NestedFields(result['dicom_headers_images_string']?.raw)}
              {NestedFields(result['dicom_headers_images_int']?.raw, true)}
              {NestedFields(result['dicom_headers_images_decimal']?.raw, true)}
            </td>
          </tr>
        );
      })}
    </>
  );
};

export default SearchTableRows;
