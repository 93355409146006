import {useState, useEffect, useMemo} from 'react';
import {useNavigate, useParams} from 'react-router-dom';
import _ from 'lodash';
import {Helmet} from 'react-helmet';
import {useHotkeys} from 'react-hotkeys-hook';
import {useQuery, useQueryClient} from 'react-query';

import {Loading} from '../../../../core/components/loading';
import {ReportComponent} from '../../../../components/report';
import {quickToast} from '../../../../core/components/toast';
import {fetchReport} from '../../../../models/report';
import {Breadcrumbs} from '../../../../core/components/breadcrumbs';
import {ApplicationShell} from '../../../../core/layout/application-shell';
import {fetchDatasetEvalonReviewAdmin} from '../../../../models/evalon';
import {useAxios} from 'src/utils/http';

interface ReportNavigation {
  currentStudyId: string;
  prevStudyId?: string;
  nextStudyId?: string;
}

export const AdminReviewReportDetails = () => {
  const navigate = useNavigate();
  const api = useAxios();
  const queryClient = useQueryClient();
  const [reportNavigation, reportNavigationChange] =
    useState<ReportNavigation>();

  const q = useParams<{
    datasetId?: string;
    studyId?: string;
    displayData: string;
  }>();
  const datasetId = _.isFinite(_.toNumber(q.datasetId))
    ? _.toNumber(q.datasetId)
    : undefined;
  const studyId = _.isString(q.studyId) ? q.studyId : undefined;
  const displayData = _.isString(q.displayData) ? q.displayData : undefined;

  const setupNavigation = (studyId: string, studies: string[]) => {
    const studyIndex = studies.indexOf(studyId);

    let prevStudyId: string | undefined;
    let nextStudyId: string | undefined;
    if (studyIndex !== -1) {
      if (studyIndex > 0) {
        prevStudyId = studies[studyIndex - 1];
      }
      if (studyIndex < studies.length - 1) {
        nextStudyId = studies[studyIndex + 1];
      }
    }
    reportNavigationChange({
      currentStudyId: studyId,
      prevStudyId,
      nextStudyId,
    });
  };

  const {
    data: report,
    error: reportError,
    isLoading: reportLoading,
  } = useQuery(
    ['report', studyId],
    () => {
      return fetchReport(api, studyId as string);
    },
    {
      enabled: _.isString(studyId),
      keepPreviousData: true,
      staleTime: 5 * 60 * 1000, // 5 minutes
    }
  );

  // Fetch admin review reports
  const {data: evalonResults, error: evalonResultsError} = useQuery(
    ['adminEvalonResults', datasetId],
    () => fetchDatasetEvalonReviewAdmin(api, datasetId!),
    {
      enabled: !_.isNil(datasetId),
      keepPreviousData: true,
      staleTime: 5 * 60 * 1000, // 5 minutes
    }
  );

  const studyIDs = useMemo(() => {
    // Split Evalon Results into each part
    const {Dicomocr: dicomocrData = [], Error: evalonErrorData = []} =
      evalonResults || {};

    const dicomocrStudyIds = dicomocrData.map(result => result.studyID);

    // Filter Dicom error results based on if sopID exists
    const dicomocrErrorStudyIds = evalonErrorData
      .filter(result => result.sopID)
      .map(result => result.studyID);

    // Choose studyIDs to display
    switch (displayData) {
      case 'dicomocr':
        return _.uniq(dicomocrStudyIds);
      case 'dicomocrError':
        return _.uniq(dicomocrErrorStudyIds);
      default:
        return _.uniq([...dicomocrStudyIds, ...dicomocrErrorStudyIds]);
    }
  }, [evalonResults, displayData]);

  const prevButtonPressed = () => {
    if (!_.isNil(reportNavigation?.prevStudyId)) {
      navigate(
        `/admin/datasets/review/${datasetId}/report/${
          reportNavigation!.prevStudyId
        }/dicomocr`
      );
    }
  };
  const nextButtonPressed = () => {
    if (!_.isNil(reportNavigation?.nextStudyId)) {
      navigate(
        `/admin/datasets/review/${datasetId}/report/${
          reportNavigation!.nextStudyId
        }/dicomocr`
      );
    }
  };

  useHotkeys('left', prevButtonPressed, [reportNavigation]);
  useHotkeys('right', nextButtonPressed, [reportNavigation]);

  useEffect(() => {
    if (_.isNil(datasetId) || !_.isNil(evalonResultsError)) {
      quickToast({title: 'Studies not found', icon: 'error'});
      navigate('/admin/datasets/review/${datasetId}');
    } else if (_.isNil(studyId) || !_.isNil(reportError)) {
      navigate(`/admin/datasets/review/${datasetId}`);
    }

    // Setup navigation
    if (
      !_.isEmpty(studyIDs) &&
      !_.isNil(report) &&
      reportNavigation?.currentStudyId !== report.studyId
    ) {
      setupNavigation(report.studyId!, studyIDs);
    }
  }, [
    studyIDs,
    evalonResultsError,
    datasetId,
    navigate,
    report,
    reportError,
    reportNavigation?.currentStudyId,
    studyId,
  ]);

  return (
    <>
      <Helmet>
        <title>Segmed Openda - Report Details</title>
      </Helmet>

      <ApplicationShell>
        <div className="mb-5">
          <Breadcrumbs
            links={[
              {name: 'Datasets Review', href: '/admin/datasets/review'},
              {
                name: `Dataset ${datasetId} Review`,
                href: `/admin/datasets/review/${datasetId}`,
              },
              {
                name:
                  report?.reportTitle ??
                  `Study ${report?.studyId.slice(-8) || 'Report'}`,
                current: true,
              },
            ]}
          />
        </div>

        {reportLoading && <Loading />}
        {report && (
          <ReportComponent
            report={report}
            reportChanged={() => {
              queryClient.refetchQueries(['dataset'], {exact: true});
            }}
            PHIReported={() => {
              queryClient.removeQueries(['search']);
              queryClient.removeQueries(['dataset']);
              queryClient.removeQueries(['report', report.studyId]);
              navigate({
                pathname: `/admin/datasets/review/${datasetId}`,
              });
            }}
            showPrevButton={!_.isNil(reportNavigation?.prevStudyId)}
            showNextButton={!_.isNil(reportNavigation?.nextStudyId)}
            onPrevButtonClick={() => prevButtonPressed()}
            onNextButtonClick={() => nextButtonPressed()}
            viewDicomUrl={`/admin/datasets/review/${datasetId}/dicomViewer/${studyId}`}
          />
        )}
      </ApplicationShell>
    </>
  );
};
