import {MinervaSearchConfigHandler} from './minerva-config-handler';
import {getConfig} from '../../../config/config-helper';

const {
  endpointBaseInternal,
  engineNameInternal,
  endpointBaseAppSearchInternal,
} = getConfig();

export const MinervaSearchInternalPage = () => {
  return (
    <MinervaSearchConfigHandler
      endpointBase={endpointBaseInternal}
      engineName={engineNameInternal}
      endpointBaseAppSearch={endpointBaseAppSearchInternal}
      isInternalPage={true}
      useVectorSearchEnabled={false}
      trackSearchType="internal_search"
      title="Segmed Openda - Search (Internal)"
    />
  );
};
